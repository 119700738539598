body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@mixin selected-underline {
	content: '';
  position: relative;
  left: 0;
  display: flex;
  width: 100%;
  border-bottom: 1px solid;
}

a {
  color: white;
  text-decoration: none;
}

a:after {
  @include selected-underline();
  opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	transform: scale(0,1);
}

.selected {
  @include selected-underline();
}

a:hover:after {
  opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
